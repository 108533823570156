<template>
  <section>
    <header class="mx-auto">
      <div class="d-flex align-items-center">
        <v-avatar class="mt-2" size="96" rounded="lg">
          <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="
            Profile.pic != null && Profile.pic != ''
              ? Profile.pic
              : require('@/assets/images/profile.jpg')
          "></v-img>
        </v-avatar>
        <div class="ms-4 me-0 d-flex flex-column">
          <span class="f14 font-weight-bold">
            {{ Profile.firstname + " " + Profile.lastname }}
          </span>

          <router-link class="my-1 f13 primary--text text-decoration-none" v-if="Profile.clinic"
            :to="`/s/clinic/${Profile.clinic.id}`">
            {{ Profile.clinic.name }}
          </router-link>

          <span class="f13 grey--text" v-if="Profile.specialties && Profile.specialties.length">
            {{ Profile.specialties[0].value }}
          </span>
        </div>
      </div>


      <p class="f14 text-justify my-4">
        {{ Profile.bio }}
      </p>

      <div class="mt-1 text-secondary">
        <v-chip-group>
          <v-chip small v-for="(ac, index) in Profile.activities" :key="index" class="m-1" color="primary">{{ ac.value
          }}
          </v-chip>
        </v-chip-group>
      </div>

      <div>

        <v-row class="d-flex align-items-center mt-3" no-gutters>
          <v-col cols="4" class="text-center" no-gutters>
            <div class="font-weight-bold">{{ Profile.postCount }}</div>
            <div class="font-weight-light"> {{ $t('Post') }}</div>
          </v-col>
          <v-col cols="4" class="text-center pointer text-decoration-none" no-gutters @click="toFollowersPage">
            <div class="font-weight-bold">{{
                Profile.followerCount
            }}</div>
            <div class="font-weight-light"> {{ $t('Follower') }}</div>
          </v-col>
          <v-col cols="4" class="text-center" no-gutters>
            <div class="font-weight-bold">{{
                Profile.questionCount
            }}</div>
            <div class="font-weight-light"> {{ $t('Question answered') }}</div>
          </v-col>
        </v-row>

        <v-row>
          <v-chip-group>
            <v-chip
                label
                v-if="Profile.phoneCallStatus"
                class="m-1 mt-4 text-center f15 white--text"
                color="green_bg"
                v-b-modal.modal-center
                @click="ModalShow = true">
              {{ $t("Call numbers") }}
            </v-chip>
            <v-chip
                label
                v-if="Profile.onlineCallStatus"
                class="m-1 mt-4 text-center f15 white--text"
                color="primary"
                v-b-modal.modal-center
                @click="ModalShow = true">
              {{ $t("APPOINTMENTS_LIST") }}
            </v-chip>
          </v-chip-group>
        </v-row>
      </div>
    </header>
    <div>
      <b-modal
          body-class="text-center"
          footer-class="text-center"
          dialog-class="text-center"
          title-class="text-center"
          hide-header-close
          v-model="ModalShow"
          centered
      >
        <b-container fluid v-if="!Profile.isClinicNumber">
          <div class="primary--text f18">
            {{ Profile.contactDescription }}
          </div>
          <div
              class="my-2"
              v-for="(number, index) in PhoneNumbers"
              :key="index"
          >
            <v-btn
                color="primary"
                elevation="0"
                outlined
                rounded
                text
                class="f16"
                :href="`tel:${number}`"
            >
              <v-icon left> mdi-phone </v-icon>
              {{ number }}
            </v-btn>
          </div>
        </b-container>
        <b-container fluid v-else>
          <div class="primary--text f18">
            {{ Profile.clinic.contactDescription }}
          </div>
          <div
              class="my-2"
          >
            <v-btn
                color="primary"
                elevation="0"
                outlined
                rounded
                text
                class="f16"
                :href="`tel:${Profile.clinic.phone}`"
            >
              <v-icon left> mdi-phone </v-icon>
              {{ Profile.clinic.phone }}
            </v-btn>
          </div>
        </b-container>
        <template #modal-header>
          <div class="w-100 text-center">{{ $t("Call numbers") }}</div>
        </template>

        <template #modal-footer class="my-0">
          <v-row class="w-100 my-0" align-v="center">
            <v-col class="col-12">
              <p class="text-danger" style="font-size: 14px">
                {{
                  $t(
                      "In critical situations, such as any child abuse, spousal abuse or suicidal thoughts, call 123 Social Emergency"
                  )
                }}
              </p>
              <b-button
                  size="md"
                  class="mb-3 btn text-light"
                  @click="ModalShow = false"
              >
                {{ $t("OK") }}
              </b-button>
            </v-col>
          </v-row>
        </template>
      </b-modal>
    </div>
    <main class="mb-2 mt-6">
      <v-tabs background-color="grey lighten-4" slider-size="0" class="rounded-lg" grow v-model="tabs">
        <v-tab active-class="primary white--text rounded-lg">
          <v-badge :content="Profile.postCount" :value="Profile.postCount" inline color="accent">
            {{ $t('Posts') }}
          </v-badge>
        </v-tab>

        <v-tab active-class="primary white--text rounded-lg">
          <v-badge :content="Profile.questionCount" :value="Profile.questionCount" inline color="accent">
            {{ $t('Answers') }}
          </v-badge>
        </v-tab>
        <!-- <span class="cursor" :style="select.video">
              ویدئو
            </span> -->
      </v-tabs>
      <!-- start post loader  -->
      <v-tabs-items v-model="tabs" class="px-0 py-2">
        <v-tab-item class="pb-2">
          <v-row v-if="posts && posts.length > 0">
            <v-col xs="12" sm="12" md="6" lg="4" xl="4" cols="12" v-for="(post, index) in posts" :key="index">
              <v-card :to="`/s/contents/${post.type.toLowerCase()}/${post.id}`" class="rounded-lg">
                <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="post.picture" :aspect-ratio="1 / 1"
                  class="d-flex align-items-center justify-content-center">
                  <div class="text-white w-100 text-center">
                    <i class="fas fa-play p-4 bg-black-50 rounded-pill" v-if="post.type == 'VIDEO'"></i>
                  </div>
                </v-img>

                <v-card-title class="f13 single-line pb-0">
                  {{ post.title }}
                </v-card-title>

                <v-card-actions class="pt-0">
                  <v-list-item two-line class="p-0">
                    <v-list-item-avatar>
                      <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="post.publisherPic"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="f12 font-weight-bold primary--text">{{ post.publisherName }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="f10">{{ post.createDate | moment("from", "now") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <span class="text-center f16 d-block mt-8" v-else>
            {{ $t('No results found') }}
          </span>
        </v-tab-item>

        <v-tab-item class="pb-2">
          <v-row v-if="questions && questions.length > 0">
            <v-col v-for="(item, index) in questions.slice().reverse()" :key="index" xs="12" sm="12" md="6" lg="4" xl="4" cols="12">
                <v-card class="my-4 rounded-lg border d-flex flex-column h-100" :to="'/s/questions/' + item.id" :href="'/s/questions/' + item.id">
                  <v-card-title class="d-flex justify-space-between align-center">
                    <div>
                      <span v-if="item.answers" class="blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12"> {{
                          item.answers.length
                      }} {{ $t("Answer") }}</span>
                      <span v-else class="blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12"> 0 {{
                          $t("Answer")
                      }}</span>
                    </div>
                    <div>
                      <span class="f13 font-weight-light"> {{ item.createDate | moment("from", "now") }} </span>
                    </div>
                  </v-card-title>

                  <v-card-text class="flex-grow-1">
                    <p class="f14 text-justify" v-html="item.question"></p>
                  </v-card-text>

                  <v-divider class="m-0" dark></v-divider>
                  <v-card-actions class="d-flex justify-space-between align-center">
                    <div v-if="item.answers && item.answers.length">
                      <div class="d-flex flex-row align-items-center">
                        <section class="d-flex flex-row avatar-group right-to-left">
                          <span v-for="(answer, index) in item.answers.slice(0, 3)" :key="index"
                            class="avatars-group__item avatar">
                            <v-avatar size="36" color="primary" class="bordered">
                              <img :src="answer.doctorProfilePic"
                                :alt="answer.doctorFullName ? answer.doctorFullName : ''"
                                v-if="answer.doctorProfilePic && index < 2" />

                              <span v-else-if="index < 2" class="white--text text-small">
                                {{ answer.doctorFullName ? answer.doctorFullName.slice(0, 1) : "" }}
                              </span>

                              <span v-else class="white--text text-small">
                                ...
                              </span>
                            </v-avatar>
                          </span>
                        </section>
                        <div>
                          <span class="f12 font-weight-normal ps-1" v-if="item.answers.length == 1">
                            {{ item.answers[0].doctorFullName ? item.answers[0].doctorFullName : '' }}
                          </span>
                          <span class="f12 font-weight-normal ps-1" v-if="item.answers.length > 1">
                            {{ item.answers.length }} {{ $t('specialist') }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <span class="bg-harmonies text-paris-pink p-2 rounded-lg f12">
                        {{ $t("Not answered") }}
                      </span>
                    </div>
                    <div>
                      <div class="text-left mt-1">
                        <div class="d-flex d-inline-flex align-items-center border border-primary rounded primary--text"
                          v-if="item.userLikeQuestions && !item.userLikeQuestions.find(x => x.mobile == $store.getters.mobileNumber)">
                          <iconly type="bold" name="heart" class="ms-1 me-0 wh-20"></iconly>
                          <span v-if="item.userLikeQuestions && item.userLikeQuestions.length" class="me-1 f12">
                            {{
                                item.userLikeQuestions.length
                            }}
                          </span>
                          <span v-else class="me-1 f12">0</span>
                        </div>
                        <div class="d-flex d-inline-flex align-items-center primary rounded white--text" v-else>
                          <iconly type="bold" name="heart" class="ms-1 me-0 wh-20" color="white"></iconly>
                          <span v-if="item.userLikeQuestions && item.userLikeQuestions.length" class="me-1 f12">
                            {{
                                item.userLikeQuestions.length
                            }}
                          </span>
                          <span v-else class="me-1 f12">0</span>
                        </div>
                        <div
                          class=" d-flex d-inline-flex align-items-center border border-primary rounded primary--text ms-2">
                          <iconly type="bold" name="show" class="ms-1 me-0 wh-20"></iconly>
                          <span class="me-1 f12">{{ item.view }}</span>
                        </div>
                      </div>
                    </div>
                  </v-card-actions>
                </v-card>
            </v-col>
          </v-row>
          <span class="text-center f16 d-block mt-8" v-else>
            {{ $t('No results found') }}
          </span>
        </v-tab-item>
      </v-tabs-items>
    </main>
  </section>
</template>
<script>
import apiService from "@/http/ApiService.js";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
import AppointmentDialog from "@/components/user/specialists/AppointmentDialog.vue";
moment.locale("fa", fa);
moment.loadPersian();


export default {
  components: {AppointmentDialog},
  data() {
    return {
      appointmentSelectionDialog:false,
      ModalShow: false,
      tabs: null,
      postHolder: {},
      PhoneNumbers: [],
      slide: 0,
      fCounter: 2,
      shows: {
        showProfile: false,
        showNotFound: false,
        loader: true,
        primaryPostsLoader: true,
        posts: false,
        questions: false,
        VideoAll: true,
        postImage: false,
        imageCounter: false,
        imageShow: false,
        noneErrPost: false,
      },
      Profile: {
        firstname: "",
        lastname: "",
      },
      posts: [],
      questions: [],
      erri: 0,
      errColor: "text-danger",
      erriPost: 2,

      images: [],
      currentIndex: 0,
      currentImageSlideNumber: 1,
      chooseSlide: "",
      ErrPostColor: "",
    };
  },
  methods: {
    toFollowersPage() {
      if (this.Profile.id == this.$store.getters.doctorId) {
        this.$router.push(`/s/specialist/${this.$store.getters.doctorId}/followers`)
      }
    },
    next: function () {
      this.currentIndex += 1;
    },
    prev: function () {
      this.currentIndex -= 1;
    },
    sliderloader(pic) {
      this.images = [];
      if (typeof pic === "string") {
        this.images.push(pic);
      }
      this.shows.postImage = true;
      this.shows.imageShow = true;

      if (this.images.length > 1) {
        this.shows.imageCounter = true;
      } else {
        this.shows.imageCounter = false;
      }
      let a = Math.abs(this.currentIndex) % this.images.length;
      this.currentImageSlideNumber = a + 1;
      let f = Math.abs(this.currentIndex) % this.images.length;
      this.chooseSlide = this.images[f];
    },

    getdoctorPublishedContentList(doctorId) {
      apiService
        .doctorPublishedContentList(doctorId)
        .then((DATA) => {
          this.posts = DATA.data;
          this.shows.primaryPostsLoader = false;
          this.shows.posts = true;
        })
        .catch((err) => {
          if (err.response.status == 404 || err.response.status == 409) {
            this.shows.primaryPostsLoader = false;
            this.shows.posts = false;
            this.shows.noneErrPost = true;
            this.erriPost = 2;
            this.VideoAll = false;
            // this.posts.id
          } else {
            this.shows.primaryPostsLoader = false;
            this.shows.posts = false;
            this.shows.noneErrPost = true;
            this.erriPost = 0;
            this.ErrPostColor = "text-danger";
          }
        });
    },
    getdoctorAnswerQuestionsList(doctorId) {
      apiService
        .doctorAnswerQuestionsList(doctorId)
        .then((DATA) => {
          this.questions = DATA.data.content;
          this.shows.questions = true;
        })
        .catch((err) => {
          if (err.response.status == 404 || err.response.status == 409) {
            this.shows.questions = false;
            // this.posts.id
          } else {
            this.shows.questions = false;
          }
        });
    },
    getDoctorInformation(id) {
      apiService
        .getDoctorInfoById(id)
        .then((DATA) => {
          if (DATA.status == 200) {
            if (DATA.data.phone != null || "") {
              this.PhoneNumbers = DATA.data.phone.split(",");
            } else {
              this.PhoneNumbers = false;
            }

            this.shows.showProfile = true;
            this.shows.loader = false;
            this.shows.showNotFound = false;
            this.Profile = DATA.data;
            if (DATA.data.followerCount <= 999) {
              this.fCounter = 2;
            } else if (
              DATA.data.followerCount >= 1000 &&
              DATA.data.followerCount <= 999999
            ) {
              this.fCounter = 1;
            } else {
              this.fCounter = 0;
            }
          } else {
            this.erri = 1;
          }
        })
        .catch((error) => {
          this.shows.loader = false;
          this.shows.showProfile = false;
          this.shows.showNotFound = true;
          this.errColor = "text-danger";
          if (error.response.status == 422 || error.response.status == 500) {
            this.erri = 1;
          } else {
            this.erri = 0;
          }
        });
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
    let id = this.$route.params.id;
    this.getDoctorInformation(id);
    this.getdoctorPublishedContentList(id);
    this.getdoctorAnswerQuestionsList(id);
  },
};
</script>